import React, { useState } from "react";
import InputOne from "../Util/Input/InputOne";
import InputWithIconButton from "../Util/Input/InputWithIconButton";
import { applyCoupenCode } from "../../API/api_request";
import { toast } from "react-toastify";

function CoupenCodeApply({ invoice_id, onSubmit }) {
  let [isToggleOpen, setToggleOpen] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState("");

  function toggleApplayCoupen() {
    setToggleOpen(!isToggleOpen);
  }

  function onCoupenSubmit(coupen_code) {
    if (!coupen_code) {
      return toast.error("Please enter a coupon code.");
    }

    if (currentCoupon === coupen_code) {
      return toast.error("This coupon is already applied!");
    }

    applyCoupenCode(coupen_code, invoice_id).then(async (coupen_status) => {
      let response = coupen_status?.data;
      let msg = response?.coupen_status?.msg ?? "Something went wrong";
      console.log(response);

      if (response?.status) {
        if (response?.coupen_status?.status) {
          toast.success(msg);
          setCurrentCoupon(coupen_code);
          // dispatch(await fetchCartDetails());
          onSubmit();
          return;
        }
      }
      return toast.error(msg);
    });
  }

  return (
    <div>
      <button class="w-100 promo-link45" onClick={toggleApplayCoupen}>
        Have a promocode?
      </button>
      {isToggleOpen ? (
        <div className="bg-white pl-3 pr-3 pb-3">
          <InputWithIconButton
            OnClick={onCoupenSubmit}
            icon={"check"}
            placeHolder={"Enter coupen code"}
            defaultValue={currentCoupon ?? ""}
          ></InputWithIconButton>
        </div>
      ) : null}
    </div>
  );
}

export default CoupenCodeApply;
