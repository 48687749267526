const EmptyAddress = () => {
  return (
    <div className="empty-address-container">
      <div className="empty-state-wrapper">
        <div className="empty-state-icon">
          <i className="uil uil-map-marker"></i>
        </div>
        <h4 className="empty-state-title">No Addresses Found</h4>
        <p className="empty-state-description">
          You haven't added any delivery addresses yet. Add an address to get started with your shopping.
        </p>
        <button className="add-address add-address-btn" data-toggle="modal" data-target="#address_model">
          <i className="uil uil-plus"></i>
          Add New Address
        </button>
      </div>
    </div>
  );
};

export default EmptyAddress;
