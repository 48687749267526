import React, { Fragment, useState, useEffect } from "react";
import SliderComponent from "../Slider/SliderComponent";
import BannerItem from "./BannerItem";
import { getBanners } from "../../API/api_request";
import { const_data } from "../../CONST/const_data";

function HomeBanner() {
  let [banners, setBanners] = useState([]);

  useEffect(() => {
    getBanners()
      .then((data) => {
        let response = data?.data;
        if (response?.status) {
          let banners = response?.banners;
          setBanners(banners);
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <Fragment>
      <div className="bannerSlider mt-3">
        <SliderComponent
          settings={{
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1.9,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                  centerMode: true,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1.3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                  centerMode: true,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                  centerMode: true,
                },
              },
            ],
          }}
        >
          {banners?.map((each) => (
            <BannerItem image={each?.images} url={each?.url}></BannerItem>
          ))}
        </SliderComponent>
      </div>
    </Fragment>
  );
}

export default HomeBanner;
