import React, { useEffect, useState } from "react";
import UserLayout from "../../../Component/UserPartials/UserLayout/UserLayout";
import Breadcrumb from "../../../Component/Util/ElementRelated/Breadcrumb";
import ProdctSearchingSideBar from "../../../Component/Products/ProdctSearchingSideBar";
import { getAllProduct } from "../../../API/api_request";
import ProductItem from "../../../Component/Product/ProductItem";
import { const_data } from "../../../CONST/const_data";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../../Component/Util/ElementRelated/LoadingSpinner";
import CartUserOverCanvas from "../../../Component/OverLay/CartUserOverCanvas";
import CategoryModalUser from "../../../Component/OverLay/CategoryModalUser";
import EmptyScreen from "../../../Component/Util/Box/EmptyScreen";

function ProductSearching() {
  let [productList, setProductList] = useState([]);
  let [tempProductList, setTempProductList] = useState([]);
  let [loading, setLoading] = useState(true);

  let productSearchData = useSelector((state) => state.productSearching);
  let [queryInstance, setQueryInstance] = useSearchParams();
  let pname = queryInstance.get("pname");

  useEffect(() => {
    setLoading(true);
    getAllProduct()
      .then((data) => {
        let response = data?.data;
        if (response?.status) {
          let products = response?.products;
          setTempProductList(products);
        }
      })
      .catch((err) => {
        console.error("Error fetching products", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (tempProductList.length === 0) return;

    setProductList((prevProductList) => {
      let newProductList = [...tempProductList];

      if (productSearchData?.category?.length) {
        let categoryList = productSearchData?.category ?? [];
        newProductList = newProductList.filter((each) => categoryList.includes(each?.category?._id));
      }

      if (productSearchData?.discount?.length === 2) {
        let [discountFrom, discountTo] = productSearchData?.discount;
        newProductList = newProductList.filter((each) => {
          let discountPercentage = ((each.original_price - each.sale_price) / each.original_price) * 100;
          return discountPercentage >= discountFrom && discountPercentage <= discountTo;
        });
      }

      if (productSearchData?.price_range) {
        let priceRange = productSearchData?.price_range;
        if (priceRange === const_data.PRODUCT_SEARCHING_OPTION.PRICE_RANGE.HIGH_TO_LOW) {
          newProductList = newProductList.sort((a, b) => b.sale_price - a.sale_price);
        } else if (priceRange === const_data.PRODUCT_SEARCHING_OPTION.PRICE_RANGE.LOW_TO_HIGH) {
          newProductList = newProductList.sort((a, b) => a.sale_price - b.sale_price);
        }
      }

      if (productSearchData?.stock_filter?.length) {
        let stock_filter = productSearchData?.stock_filter ?? [];
        if (stock_filter.includes(const_data.PRODUCT_SEARCHING_OPTION.STOCK_FILTER.IN_STOCK)) {
          newProductList = newProductList.filter((each) => each?.stock >= 1);
        } else if (stock_filter.includes(const_data.PRODUCT_SEARCHING_OPTION.STOCK_FILTER.OUT_OF_STOCK)) {
          newProductList = newProductList.filter((each) => each?.stock <= const_data.MIN_STOCK);
        }
      }

      if (productSearchData?.price_between?.length === 2) {
        let [price_from, price_to] = productSearchData?.price_between;
        newProductList = newProductList.filter(
          (each) => each.sale_price >= price_from && each.sale_price <= price_to
        );
      }

      if (pname) {
        newProductList = newProductList.filter((each) =>
          each.name.toLowerCase().includes(pname.toLowerCase())
        );
      }

      if (newProductList.length === 0 && prevProductList.length === 0) {
        return tempProductList;
      }

      return newProductList;
    });

  }, [tempProductList, productSearchData, pname]);

  return (
    <div>
      {loading ? <LoadingSpinner isShow={true} /> : null}

      <CartUserOverCanvas />
      <CategoryModalUser />
      <UserLayout>
        <Breadcrumb pageName={"Product Searching"} />
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-3">
              <ProdctSearchingSideBar />
            </div>
            <div className="col-md-9">
              <div className="row">
                {productList.length < 1 ? (
                  <EmptyScreen
                    bgColor={"white"}
                    content={"Please check the spelling or try searching for something else"}
                    title={"Sorry, no results found!"}
                  />
                ) : (
                  productList.map((item) => (
                    <div className="col-md-4" key={item?._id}>
                      <ProductItem
                        _id={item?._id}
                        original_price={item?.original_price}
                        sale_price={item?.sale_price}
                        product_image={item?.images[0]}
                        stock={item?.stock}
                        title={item?.name}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </UserLayout>
    </div>
  );
}

export default ProductSearching;
