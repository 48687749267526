import React, { useEffect, useRef, useState } from "react";
import { getUserAddress } from "../../API/api_request";
import Button1 from "../Util/Buttons/Button1";
import EmptyAddress from "./EmptyAddress";

function SelectAddressOverlay({ state, allAddress, confirmButton = false }) {
  let [selectedAddress, setSelectedAddress] = useState(null);

  let modalCloseBtn = useRef(null);

  function addressSelectChange(e) {
    let address_id = e.target.value;
    if (confirmButton) {
      setSelectedAddress(address_id);
    } else {
      state(address_id);
      modalCloseBtn.current.click();
    }
  }

  function addressSubmit() {
    if (selectedAddress != "" && selectedAddress != null) {
      state(selectedAddress);
      modalCloseBtn.current.click();
    }
  }

  return (
    <div
      id="select_address_model"
      className="header-cate-model main-gambo-model modal fade"
      tabIndex="-1"
      role="dialog"
      aria-modal="false"
    >
      <div className="modal-dialog category-area" role="document">
        <div className="category-area-inner">
          <div className="modal-header">
            <button
              ref={modalCloseBtn}
              type="button"
              className="close btn-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="uil uil-multiply"></i>
            </button>
          </div>
          <div className="category-model-content modal-content">
            <div className="cate-header">
              <h4>Select Address</h4>
            </div>
            <div className="add-address-form">
              <div className="checout-address-step">
                <div className="row">
                  <div className="col-lg-12">
                    {allAddress.length === 0 ? (
                      <EmptyAddress />
                    ) : (
                      <>
                        <button
                          className="add-address hover-btn mb-2 mt-0 ml-0"
                          data-toggle="modal"
                          data-target="#address_model"
                        >
                          Add New Address
                        </button>
                        <ul
                          className="d-block radio--group-inline-container_1"
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {allAddress.map((addressItem, index) => (
                            <li className="w-100" key={addressItem._id}>
                              <div className="radio-item_1">
                                <input
                                  value={addressItem._id}
                                  onChange={addressSelectChange}
                                  id={"addressItem" + index}
                                  name="addressItem"
                                  type="radio"
                                  checked={selectedAddress === addressItem._id}
                                />
                                <label htmlFor={"addressItem" + index} className="radio-label_1 d-flex">
                                  <div style={{ flex: "3" }}>
                                    <h5>{addressItem.name}</h5>
                                    <p>{addressItem.address}</p>
                                  </div>
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {confirmButton && allAddress.length > 0 && (
              <div className="cate-header">
                <Button1 isFullWidth={true} type="button" onClick={addressSubmit} title="Select Address" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectAddressOverlay;
