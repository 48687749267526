import React, { Fragment } from "react";
import { const_data } from "../../../CONST/const_data";
import { useSelector } from "react-redux";

function Payment({ stateField, sub_total }) {
  let userData = useSelector((state) => state.userAuth.user);

  function onPaymentOptionChange(e) {
    stateField(e.target.value);
  }

  const renderPaymentOption = (method, index) => {
    const isWallet = method === const_data.PAYMENT_METHOD.WALLET;
    const isWalletInsufficient = isWallet && userData.wallet_amount < sub_total;

    return (
      <li key={index} className="payment-option">
        <label className="payment-radio-wrapper" htmlFor={`payment-${method}-${index}`}>
          <input
            onChange={onPaymentOptionChange}
            id={`payment-${method}-${index}`}
            value={method}
            name="paymentmethod"
            type="radio"
            disabled={isWalletInsufficient}
          />
          <div className="payment-content">
            <span className="method-name">{method}</span>
            {isWallet && (
              <div className="wallet-details">
                <span className={`wallet-balance ${isWalletInsufficient ? "insufficient" : ""}`}>
                  Balance: {const_data.CURRENCY_ICON}
                  {userData.wallet_amount.toFixed(2)}
                </span>
                {isWalletInsufficient && <span className="insufficient-notice">Insufficient balance</span>}
              </div>
            )}
          </div>
        </label>
      </li>
    );
  };

  return (
    <Fragment>
      <div class="checkout-card" id="headingFour">
        <span class="checkout-step-number">3</span>
        <h4 class="checkout-step-title">
          <button
            class="wizard-btn collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            Payment
          </button>
        </h4>
      </div>
      <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#checkout_wizard">
        <div class="checkout-step-body">
          <div class="payment_method-checkout">
            <div class="row">
              <div class="col-md-12">
                <div class="rpt100">
                  <ul class="radio--group-inline-container_1">
                    {Object.values(const_data.PAYMENT_METHOD).map(renderPaymentOption)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Payment;
